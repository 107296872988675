import React from 'react'
import dynamic from 'next/dynamic'
import isEmpty from 'lodash/isEmpty'
import { RECOMMENDED_CAT_PAGE } from '../ProductCollection/RecommendationConstants'
import { PAGE_TYPES } from '../../lib/eventTrackerFunction'
import BubbleCategoryCollection from '../BubbleCategoryCollection/BubbleCategoryCollection'
const CategoryCollection = dynamic(() =>
  import('../CategoryCollection/CategoryCollection')
)
const ProductCollection = dynamic(() => import('../ProductCollection'))
const SearchSuggestionCollection = dynamic(() =>
  import('../SearchSuggestionCollection')
)

const BrandCollection = dynamic(() =>
  import('../BrandCollection/BrandCollection')
)
const ImageSlideShow = dynamic(() => import('../ImageSlideShow'))
const LayoutImage = dynamic(() => import('../LayoutImage'))
const LayoutContentBlock = dynamic(() => import('../LayoutContentBlock'))
const InlineBanners = dynamic(() => import('../InlineBanners'))
const VoucherSwimlane = dynamic(() => import('../VoucherSwimlane'))
const ProductCollectionWithBanner = dynamic(() =>
  import('../ProductCollectionWithBanner/ProductCollectionWithBanner')
)
const PromoCollection = dynamic(() =>
  import('../PromoCollection/PromoCollection')
)
const ImageVoucherSwimlane = dynamic(() => import('../ImageVoucherSwimlane'))

const components = {
  ProductCollection: 'ProductCollection',
  CategoryCollection: 'CategoryCollection',
  BrandCollection: 'BrandCollection',
  ImageSlideShow: 'ImageSlideShow',
  MarketingBanners: 'ImageSlideShow',
  SearchResults: 'ProductCollection',
  Image: 'LayoutImage',
  ContentBlock: 'LayoutContentBlock',
  InlineBanners: 'InlineBanners',
  VoucherSwimlane: 'VoucherSwimlane',
  SearchSuggestionCollection: 'SearchSuggestionCollection',
  ProductCollectionWithBanner: 'ProductCollectionWithBanner',
  PromoCollection: 'PromoCollection',
  ImageVoucherSwimlane: 'ImageVoucherSwimlane',
}

export const getLayoutByName = (layouts = [], name, pageType = '') => {
  if ([PAGE_TYPES.PROMO, PAGE_TYPES.TAG].includes(pageType)) {
    return layouts.find(
      layout => layout.name === name && layout.data?.loadMoreType !== 'SEEALL'
    )
  } else {
    return layouts.find(
      layout =>
        layout.name === name && layout.value?.title !== RECOMMENDED_CAT_PAGE
    )
  }
}

export const loadLayouts = (
  layouts,
  additionalConfig,
  loadComponentBefore,
  componentToLoad,
  additionalProps,
  preLoadFirstImage
) => {
  return (
    <React.Fragment>
      {layouts.map((layout, index) => {
        const configObj = Object.assign({}, additionalConfig)
        let Component = getComponent(layout.name)
        if (isEmpty(layout.value)) {
          Component = ''
        }
        let loadComponent = false
        if (configObj?.pageType === PAGE_TYPES.PROMO) {
          if (
            layout.name === loadComponentBefore &&
            layout?.data?.loadMoreType === 'INFINITE' &&
            layout?.value?.collection?.count > 0
          ) {
            loadComponentBefore = 'load'
            loadComponent = true
          }
        } else if (layout.name === loadComponentBefore) {
          loadComponentBefore = 'load'
          loadComponent = true
        }

        if (layout.value && layout.value.title) {
          if (layout.value.title === 'Recommended_Cat_Page') {
            configObj.loc = `ProductListing-${layout.value.title}`
          } else if (layout?.data?.loadMoreType === 'SEEALL') {
            configObj.loc = `ProductWidget-${layout.value.title
              .replace(',', '')
              .replace(/\s/g, '')}`
          }
        }
        // to avoid invariant validation error in case component is null or undefined
        if (!Component) {
          return null
        }
        return (
          <React.Fragment key={`component-${layout.name}${index}`}>
            {loadComponent && componentToLoad}
            <Component
              preLoadFirstImage={preLoadFirstImage}
              data={layout.value}
              config={{ ...layout.data, ...configObj }}
              {...additionalProps}
            />
          </React.Fragment>
        )
      })}
    </React.Fragment>
  )
}

export const getComponent = (component, data) => {
  switch (components[component]) {
    case 'ProductCollection':
      return ProductCollection
    case 'CategoryCollection': {
      const title = data?.title || ''
      if (title.toLowerCase() === 'top categories') {
        return BubbleCategoryCollection
      }
      return CategoryCollection
    }
    case 'BrandCollection':
      return BrandCollection
    case 'ImageSlideShow':
      return ImageSlideShow
    case 'LayoutImage':
      return LayoutImage
    case 'LayoutContentBlock':
      return LayoutContentBlock
    case 'InlineBanners':
      return InlineBanners
    case 'VoucherSwimlane':
      return VoucherSwimlane
    case 'SearchSuggestionCollection':
      return SearchSuggestionCollection
    case 'ProductCollectionWithBanner':
      return ProductCollectionWithBanner
    case 'PromoCollection':
      return PromoCollection
    case 'ImageVoucherSwimlane':
      return ImageVoucherSwimlane
  }
  return null
}
